<template>
  <div class="d-flex flex-column">
    <h4 class="font-weight-bold py-5">{{ title }}</h4>
    <b-form class="form" v-on:submit.prevent="saveHoliday">
      <!-- begin:date  -->
      <b-form-group label-for="holiday-date-input">
        {{ $t("HOLIDAY.DATE") }}
        <b-form-datepicker
          id="holiday-date-input"
          class="form-control h-auto"
          v-model="$v.form.date.$model"
          :state="validateState('date')"
        >
        </b-form-datepicker>
      </b-form-group>
      <!-- end:date  -->

      <!-- begin: Holiday     -->
      <b-form-group label-class="text-bolder">
        {{ $t("HOLIDAY.HOLIDAY") }}
        <b-form-input
          id="holiday"
          class="form-control h-auto"
          v-model="$v.form.holiday.$model"
          :state="validateState('holiday')"
          :placeholder="$t('HOLIDAY.HOLIDAY1')"
        >
        </b-form-input
      ></b-form-group>
      <!-- end: Holiday     -->

      <!-- begin:remark  -->
      <b-form-group label-for="holiday-desc-input">
        {{ $t("JOB.REMARK") }}
        <b-form-textarea
          id="holiday-desc-input"
          class="form-control h-auto"
          :placeholder="$t('JOB.REMARK')"
          rows="3"
          max-rows="6"
          v-model="$v.form.remark.$model"
          :state="validateState('remark')"
        >
        </b-form-textarea>
      </b-form-group>
      <!-- end:remark  -->

      <!--begin::Action-->
      <div class="form-group d-flex flex-column">
        <button
          type="submit"
          ref="holiday-form-submit"
          class="btn btn-primary font-weight-bolder"
          v-bind:class="{ 'spinner spinner-light spinner-right': isBusy }"
        >
          {{ $t("COMPANY.REGISTRATION.SUBMIT") }}
        </button>
      </div>
      <!--end::Action-->
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "HolidaySettingsForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: "Holiday Form",
    },
  },
  data() {
    return {
      form: {
        date: "",
        holiday: "",
        remark: "",
      },
      isBusy: false,
    };
  },
  validations: {
    form: {
      date: {
        required,
      },
      holiday: {
        required,
      },
      remark: {
        required,
        maxLength: maxLength(2000),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    saveHoliday() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      const date = this.$v.form.date.$model;
      const holiday = this.$v.form.holiday.$model;
      const remark = this.$v.form.remark.$model;

      this.isBusy = true;
      this.$store
        .dispatch("saveCompanyHoliday", {
          date,
          name: holiday,
          description: remark,
        })
        .then((response) => {
          this.$emit("close", response.data);
        })
        .finally(() => (this.isBusy = false));
    },
    close(response) {
      return response;
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.CompanyModule.holiday.form.response,
    }),
  },
};
</script>

<style scoped></style>
