<template>
  <div>
    <b-table
      ref="holiday-settings-table"
      responsive="sm"
      :fields="fields"
      :items="getCompanyHolidayList"
      :busy="getCompanyHolidayListBusy"
      table-class="table-head-custom table-vertical-center mt-10"
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>
      <!-- end: t-row -->

      <!--  begin:: actions    -->
      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="deleteHoliday(data)"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-holiday button -->
        </div>
      </template>
      <!--  end:: actions    -->
      <template #table-busy>
        <AppLoader></AppLoader>
      </template>
    </b-table>
    <b-pagination
      v-if="getCompanyHolidayList.length"
      class="pt-5 pb-2"
      pills
      size="lg"
      align="right"
      v-model="getCompanyHolidayListPagination.current_page"
      :total-rows="getCompanyHolidayListPagination.total"
      :per-page="getCompanyHolidayListPagination.per_page"
      @change="changePage"
    ></b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import swal from "sweetalert";

export default {
  name: "HolidaySettingsTable",
  components: { AppLoader },
  props: ["reload"],
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "#" },
        { key: "name", label: this.$t("HOLIDAY.HOLIDAY") },
        // A virtual column made up from two fields
        { key: "date", label: this.$t("HOLIDAY.DATE") },
        { key: "day", label: this.$t("HOLIDAY.DAY") },
        { key: "actions", label: this.$t("EMPLOYEETABLE.ACTIONS") },
      ],
    };
  },
  mounted() {
    return this.$store.dispatch("fetchCompanyHolidays");
  },
  methods: {
    changePage(page) {
      this.$store.commit("setCompanyHolidayListPaginationPage", page);
      this.$store.dispatch("fetchCompanyHolidays");
    },
    showHolidayEditForm(data) {
      console.log(data);
      this.$bvModal.show("employee-group-edit-modal");
    },
    deleteHoliday(data) {
      console.log(data);
      const groupName = data.item.name;
      swal({
        title: this.$t("ALERT.SURE"),
        text: groupName + " will be deleted",
        icon: "warning",
        showCancelButton: true,
        dangerMode: true,
        button: {
          text: this.$t("BUTTON.OKAY"),
          closeModal: false,
        },
        closeOnConfirm: false,
        closeOnCancel: false,
      })
        .then((willDelete) => {
          if (willDelete) {
            return this.$store
              .dispatch("deleteCompanyHoliday", data.item.id)
              .then(() => this.$store.dispatch("fetchCompanyHolidays"));
          } else {
            throw null;
          }
        })
        .then((data) => data)
        .then(() => {
          swal(this.$t("ALERT.CONFIRM"), {
            icon: "success",
            text: groupName + " successfully deleted",
          });
        })
        .catch((err) => {
          if (err) {
            swal("Oh noes!", "The AJAX request failed!", "error");
          } else {
            swal.stopLoading();
            swal.close();
          }
        });
    },
  },
  computed: {
    ...mapGetters([
      "getCompanyHolidayList",
      "getCompanyHolidayListPagination",
      "getCompanyHolidayListBusy",
    ]),
  },
  watch: {
    reload() {
      console.log("Reload Table!");
      this.$store.dispatch("fetchCompanyHolidays");
    },
  },
};
</script>

<style scoped></style>
