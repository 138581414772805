import { render, staticRenderFns } from "./HolidaySettingsTable.vue?vue&type=template&id=446513a2&scoped=true&"
import script from "./HolidaySettingsTable.vue?vue&type=script&lang=js&"
export * from "./HolidaySettingsTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "446513a2",
  null
  
)

export default component.exports